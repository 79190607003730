.text {
  &-primary {
    color: $primary;
  }
  &-gray-600 {
    color: $gray-600;
  }
}

.bg {
  &-white {
    background-color: $white;
  }
  &-gray-400 {
    background-color: $gray-400;
  }
}