// @[Color Palette]
// Default Colors
$white:                             #FFFFFF;

// Gray Shades
$gray-100:                          #FFFFFF;
$gray-200:                          #FAFBFC;
$gray-300:                          #F4F7FA;
$gray-350:                          #EEF4F9;
$gray-400:                          #E0E9F1;
$gray-500:                          #ABBDCF;
$gray-600:                          #6D8297;
$gray-700:                          #3C4151;

// Main Colors
$purple:                            #6371DE;
$red:                               #CC0404;
$orange:                            #FF7E61;
$green:                             #32BF84;

// Secondary Colors
$beige:                             #F6F0E6;
$corn:                              #F8DE7E;
$green-light:                       #D6F2E6;
$red-light:                         #FFE6E6;

// Tints & Shades
$purple-100:                        #E1E4F9;
$purple-200:                        #B7BEF0;
$purple-300:                        #8F99FB;
$purple-400:                        #4B57DB;
$purple-500:                        #2637B5;
$purple-600:                        #1D2A8B;
$purple-700:                        #141D61;

$orange-100:                        #FFEEEB;
$orange-200:                        #FFD1C7;
$orange-300:                        #FFA794;
$orange-400:                        #FF542E;
$orange-500:                        #FA2E00;
$orange-600:                        #C72400;
$orange-700:                        #941B00;

// Brand Colors
$paypal:                            #FFC700;