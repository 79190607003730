
@mixin border-radius {
  $radius: 5 !default;
  $times: 4;

  @for $i from 1 through $times {
    .rounded-#{$i} {
      border-radius: $i * $radius;
    }
  }
}

@include border-radius;