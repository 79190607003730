* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: $primary;
}

html,
body {
  font-family: $primary-font;
  font-size: $primary-font-size;
  line-height: $primary-line-height;
  letter-spacing: $primary-letter-spacing;
  font-weight: $primary-font-weight;
  color: $primary-font-color;
  background-color: $primary-bg-color;
}

img {
  max-width: 100%;
}

hr {
  border-top-color: $border-color;
}