// Column System: 12 columns
@mixin column-system {
  $col-size: 8.333333% !default;
  $times: 12;

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col, [class*="col-"] {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }

  @for $i from 1 through $times {
    .col-#{$i} {
      max-width: $i * $col-size;
    }
  }
}

@include column-system;

.container {
  max-width: 1124px;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  @extend .d-flex;
  @extend .flex-wrap;
  margin-left: -12px;
  margin-right: -12px;
}
