.app-item {
  .app-title {
    height: auto;
    max-height: 0;
    border-top: 3px dashed transparent;
    overflow: hidden;
    @include transition($transition-fast);
  }

  &:hover {
    .app-title {
      max-height: 80px;
      border-top-color: $gray-400;
      padding-top: 12px;
      padding-bottom: 10px;
    }
  }
}