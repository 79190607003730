.w{
  &-46-px {
    width: 46px;
  }
}

.h {
  &-46-px {
    height: 46px;
  }
}