// @[Definitions]
// Base
$primary-font:                       $base-font;
$primary-font-size:                  $text-md-fz;
$primary-line-height:                $text-md-lh;
$primary-letter-spacing:             $text-md-ls;
$primary-font-color:                 $gray-700;
$primary-bg-color:                   $gray-300;
$primary-font-weight:                400;

// Theme colors
$primary:                            $purple;
$secondary:                          $gray-600;
$secondary-light:                    $gray-500;
$success:                            $green;
$success-light:                      $green-light;
$warning:                            $orange;
$danger:                             $red;
$danger-light:                       $red-light;
$disabled:                           $gray-300;

// Buttons
$btn-h:                           40px;
$btn-fz:                          16px;
$btn-lh:                          36px;
$btn-p:                           0 25px;
$btn-radius:                         10px;
$btn-fw:                             700;

$btn-primary-bg:                     $primary;
$btn-primary-color:                  $white;
$btn-primary-border:                 $primary;
$btn-primary-hover-bg:               $purple-400;
$btn-primary-hover-border:           $purple-400;
$btn-primary-active-bg:              $primary;
$btn-primary-active-border:          $purple-500;

$btn-outline-primary-border:         $primary;

$btn-disabled-bg:                    $gray-400;
$btn-disabled-border:                $gray-400;
$btn-disabled-color:                 $gray-500;

// Transitions
$base-timing-function:               ease-in-out;

$transition-fast:                    150ms $base-timing-function;
$transition-normal:                  300ms $base-timing-function;
$transition-slow:                    450ms $base-timing-function;