.position {
  &-absolute {
    position: absolute;
  }
  &-relative {
    position: relative;
  }
}

.t {
  &-0 {
    top: 0;
  }
}

.b {
  &-0 {
    bottom: 0;
  }
}

.l {
  &-0 {
    left: 0;
  }
}

.r {
  &-0 {
    right: 0;
  }
}