$spacer: 2px !default;
$times: 100;

// Paddings
@mixin paddings {
  @for $i from 0 through $times {
    .pt-#{$i} {
      padding-top: $i * $spacer;
    }
    .pb-#{$i} {
      padding-bottom: $i * $spacer;
    }
    .pl-#{$i} {
      padding-left: $i * $spacer;
    }
    .pr-#{$i} {
      padding-right: $i * $spacer;
    }
    .px-#{$i} {
      padding-left: $i * $spacer;
      padding-right: $i * $spacer;
    }
    .py-#{$i} {
      padding-top: $i * $spacer;
      padding-bottom: $i * $spacer;
    }
    .p-#{$i} {
      padding: $i * $spacer;
    }
  }
}


// Margins
@mixin margins {
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  @for $i from 0 through $times {
    .mt-#{$i} {
      margin-top: $i * $spacer;
    }
    .mb-#{$i} {
      margin-bottom: $i * $spacer;
    }
    .ml-#{$i} {
      margin-left: $i * $spacer;
    }
    .mr-#{$i} {
      margin-right: $i * $spacer;
    }
    .mx-#{$i} {
      margin-left: $i * $spacer;
      margin-right: $i * $spacer;
    }
    .my-#{$i} {
      margin-top: $i * $spacer;
      margin-bottom: $i * $spacer;
    }
    .m-#{$i} {
      margin: $i * $spacer;
    }
  }
}

@include paddings;
@include margins;