// @[Typography]
// Titles
$h1-fz:                              46px;
$h1-lh:                              64px;
$h1-ls:                              unset;
$h1-fw:                              700;

$h2-fz:                              40px;
$h2-lh:                              60px;
$h2-ls:                              unset;
$h2-fw:                              700;

$h3-fz:                              32px;
$h3-lh:                              48px;
$h3-ls:                              unset;
$h3-fw:                              700;

$h4-fz:                              28px;
$h4-lh:                              42px;
$h4-ls:                              unset;
$h4-fw:                              700;

$h5-fz:                              26px;
$h5-lh:                              40px;
$h5-ls:                              unset;
$h5-fw:                              700;

$h6-fz:                              22px;
$h6-lh:                              36px;
$h6-ls:                              unset;
$h6-fw:                              700;

$h7-fz:                              18px;
$h7-lh:                              30px;
$h7-ls:                              unset;
$h7-fw:                              700;

$h8-fz:                              16px;
$h8-lh:                              26px;
$h8-ls:                              unset;
$h8-fw:                              700;

$h9-fz:                              14px;
$h9-lh:                              24px;
$h9-ls:                              unset;
$h9-fw:                              700;

$h-mb:                               16px;

// Texts
$text-lg-fz:                         18px;
$text-lg-ls:                         unset;
$text-lg-lh:                         28px;

$text-md-fz:                         16px;
$text-md-ls:                         unset;
$text-md-lh:                         24px;

$text-sm-fz:                         14px;
$text-sm-ls:                         unset;
$text-sm-lh:                         20px;

$text-xs-fz:                         12px;
$text-xs-ls:                         unset;
$text-xs-lh:                         16px;

$p-mb:                               16px;
