.d-block {
  display: block;
}

.cursor {
  &-default {
    cursor: default
  }
  &-pointer {
    cursor: pointer
  }
  &-text {
    cursor: text;
  }
}

.overflow {
  &-hidden {
    overflow: hidden;
  }
}