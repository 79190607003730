.btn {
  font-weight: $btn-fw;
  border-radius: $btn-radius;
  height: $btn-h;
  font-size: $btn-fz;
  line-height: $btn-lh;
  padding: $btn-p;
  border: 2px solid transparent;
  white-space: nowrap;
  background-color: transparent;
  cursor: pointer;
  @include transition($transition-fast);

  &.btn-icon {
    padding: 0;
    width: $btn-h;
  }

  .loader {
    float: right;
    margin-top: 5px;
    margin-right: -10px;
    margin-left: 12px;
  }

  &:not(:disabled):not(.disabled) {
    &:focus, &:focus:active {
      box-shadow: none;
    }
  }

  // Button types
  &-primary,
  &-outline-primary {
    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: $btn-primary-hover-bg;
        border-color: $btn-primary-hover-border;
      }
      &:active {
        background-color: $btn-primary-active-bg;
        border-color: $btn-primary-active-border;
      }
    }
  }
  &-primary {
    &, &:focus {
      color: $btn-primary-color;
      background-color: $btn-primary-bg;
      border-color: $btn-primary-border;
    }
    &-loading {
      &:not(:disabled):not(.disabled) {
        &, &:hover, &:active, &:focus:active {
          background-color: $purple-300;
          border-color: $purple-300;
          cursor: default
        }
      }
    }
  }
  &-outline-primary {
    color: $btn-primary-border;
    border-color: $btn-outline-primary-border;

    &:not(:disabled):not(.disabled) {
      &:hover, &:active {
        color: $white;
      }
    }
  }

  &.btn-text {
    &-primary {
      @extend .btn-outline-primary;
      border-color: transparent;

      &:not(:disabled):not(.disabled):not(.referral-btn) {
        &:hover {
          color: $purple-400 !important;
          background-color: transparent !important;
          border-color: transparent !important;
        }

        &:active {
          color: $purple-500 !important;
          background-color: transparent !important;
          border-color: transparent !important;
        }

        &:focus:active {
          box-shadow: none;
        }
      }

      &:disabled,
      &.disabled {
        border-color: transparent;
        color: $btn-disabled-color;
        pointer-events: none;
      }
    }
  }

  &:disabled, &.disabled {
    opacity: 1;
  }

  // Button behaviors
  &-block {
    display: block;
    width: 100%;
  }

  // Button States
  &:not(.btn-text-primary):disabled,
  &:not(.btn-text-primary).disabled {
    background-color: $btn-disabled-bg;
    border-color: $btn-disabled-border;
    color: $btn-disabled-color;
  }
}