.termination-modal {
  background-color: transparentize($gray-700, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  &-content {
    width: 500px;
    padding: 40px;
    background-color: $white;
    border-radius: $radius * 4;
    @extend .shadow-1;
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
  }
}