.d-flex {
  display: flex;
}

.flex {
  &-wrap {
    flex-wrap: wrap;
  }
  &-column {
    flex-direction: column;
  }
  &-fill {
    flex: 1 1 auto;
  }
}

.align-items {
  &-center {
    align-items: center;
  }
}

.justify-content {
  &-between {
    justify-content: space-between;
  }
}