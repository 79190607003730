@mixin text-overflow ($overflow-type){
  text-overflow: $overflow-type;
  overflow: hidden;
  white-space: nowrap;
}

@mixin ellipsis() {
  @include text-overflow(ellipsis);
}

@mixin clip() {
  @include text-overflow(clip);
}

