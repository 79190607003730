h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h7,
.h8 {
  margin-bottom: $h-mb;
}

h1, .h1 {
  font-size: $h1-fz;
  line-height: $h1-lh;
  letter-spacing: $h1-ls;
  font-weight: $h1-fw;
}
h2, .h2 {
  font-size: $h2-fz;
  line-height: $h2-lh;
  letter-spacing: $h2-ls;
  font-weight: $h2-fw;
}
h3, .h3 {
  font-size: $h3-fz;
  line-height: $h3-lh;
  letter-spacing: $h3-ls;
  font-weight: $h3-fw;
}
h4, .h4 {
  font-size: $h4-fz;
  line-height: $h4-lh;
  letter-spacing: $h4-ls;
  font-weight: $h4-fw;
}
h5, .h5 {
  font-size: $h5-fz;
  line-height: $h5-lh;
  letter-spacing: $h5-ls;
  font-weight: $h5-fw;
}

h6, .h6 {
  font-size: $h6-fz;
  line-height: $h6-lh;
  letter-spacing: $h6-ls;
  font-weight: $h6-fw;
}

.h7 {
  font-size: $h7-fz;
  line-height: $h7-lh;
  letter-spacing: $h7-ls;
  font-weight: $h7-fw;
}

.h8 {
  font-size: $h8-fz;
  line-height: $h8-lh;
  letter-spacing: $h8-ls;
  font-weight: $h8-fw;
}

.h9 {
  font-size: $h9-fz;
  line-height: $h9-lh;
  letter-spacing: $h9-ls;
  font-weight: $h9-fw;
}

p {
  margin-bottom: $p-mb;
}

.fw {
  &-100 {
    font-weight: 100
  }
  &-200 {
    font-weight: 200
  }
  &-300,
  &-light {
    font-weight: 300
  }
  &-400,
  &-regular {
    font-weight: 400
  }
  &-500,
  &-medium {
    font-weight: 500
  }
  &-600 {
    font-weight: 600
  }
  &-700,
  &-bold {
    font-weight: 700
  }
  &-800 {
    font-weight: 800
  }
  &-900 {
    font-weight: 900
  }
}

.text {
  // Sizes
  &-lg {
    font-size: $text-lg-fz;
    letter-spacing: $text-lg-ls;
    line-height: $text-lg-lh
  }
  &-md {
    font-size: $text-md-fz;
    letter-spacing: $text-md-ls;
    line-height: $text-md-lh
  }
  &-sm {
    font-size: $text-sm-fz;
    letter-spacing: $text-sm-ls;
    line-height: $text-sm-lh
  }
  &-xs {
    font-size: $text-xs-fz;
    letter-spacing: $text-xs-ls;
    line-height: $text-xs-lh
  }

  // Alignment
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-justify {
    text-align: justify;
  }

  // Behavior
  &-capitalize {
    text-transform: capitalize;
  }
  &-undecorated {
    &, &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
  &-ellipsis {
    @include ellipsis();
  }
  &-clip {
    @include clip();
  }
}